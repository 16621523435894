import React from "react";
import ExpertsBox from "./components/ExpertsBox";
import "./Experts.styles.scss";

const Experts = ({ title, description, items = [] }) => {
  return (
    <section className="experts">
      <div className="experts__inner container">
        <div className="experts__left-side">
          {items?.map(({ fullname, job, image }) => {
            return (
              <ExpertsBox
                title={fullname}
                job={job}
                filename={image}
              />
            );
          })}
        </div>
        <div className="experts__right-side">
          <h2 className="title-2">{title}</h2>
          <p className="description-2">{description}</p>
        </div>
      </div>
    </section>
  );
};

export default Experts;
