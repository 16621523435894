import React from "react";
import Header from "@layouts/Header";

import Clients from "@sections/Clients";
import Info from "@sections/Info";
import InfoVertical from "@sections/InfoVertical";
import Experts from "@sections/Experts";
import ContactForm from "@containers/ContactForm";
import Box from "@components/Box";

import "./About.styles.scss";

const About = ({ data = {} }) => {
  const {
    header = {},
    firstSection = {},
    what = {},
    helping = {},
    experts = {},
    promise = {},
    contact = {},
  } = data;
  return (
    <div className="about">
      <Header {...header} />

      <div className="about__message">
        <span>You're in good company</span>
      </div>

      <Clients />

      <Info {...firstSection} />
      <InfoVertical {...what} />
      <Info {...helping} />
      <Experts {...experts} />
      <div className="about__testimonials">
        <InfoVertical {...promise}>
          {promise?.boxes?.map((box = {}) => {
            return <Box {...box} />;
          })}
        </InfoVertical>
      </div>
      <ContactForm {...contact} />
    </div>
  );
};

export default About;
