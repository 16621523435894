import React from "react";
// import StaticImage from "@components/StaticImage";
import BoxIcon from "@components/svg/BoxIcon";

import "./Box.styles.scss";

const Box = ({ title, description }) => {
  return (
    <div className="box">
      <div className="box__image">
        <BoxIcon />
      </div>
      <div className="box__details">
        <div className="box__title">{title}</div>
        <div className="box__description">{description}</div>
      </div>
    </div>
  );
};

export default Box;
