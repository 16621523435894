import React from "react";
import AboutTemplate from "@templates/About/About";

import Layout from "@layouts/Layout";

const IndexPage = ({ data: initialData }) => {
  const cmsData = initialData?.markdownRemark?.frontmatter;
  const seo = {
    ...(cmsData?.seo || {}),
    image: cmsData?.seo?.image?.childImageSharp?.fluid?.src,
  };

  const data = {
    ...(cmsData || {}),
    helping: {
      ...(cmsData?.helping || {}),
      isReverse: true,
    },
  };

  const navbar = initialData?.navbar?.frontmatter;

  return (
    <Layout navbar={navbar} seo={seo}>
      <AboutTemplate data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query AboutPageTemplate {
    navbar: markdownRemark(
      frontmatter: { templateKey: { eq: "NavbarComponent" } }
    ) {
      frontmatter {
        phone
        Items {
          name
          link
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "About" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          description
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        firstSection {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        what {
          title
          description
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        helping {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        promise {
          title
          description
          boxes {
            title
            description
          }
        }
        contact {
          title
        }
        experts {
          title
          description
          items {
            fullname
            job
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
