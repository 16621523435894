import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="98"
      fill="none"
      viewBox="0 0 98 98"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M93.95 58.05L58.1 93.9a9.998 9.998 0 01-14.15 0L1 51V1h50l42.95 42.95a10 10 0 010 14.1v0z"
      ></path>
    </svg>
  );
}

export default Icon;
