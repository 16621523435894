import React from "react";
import Image from "@components/Image";

import "./ExpertsBox.styles.scss";

const ExpertsBox = ({ filename, title, job }) => {
  return (
    <div className="experts-box">
      <Image data={filename} />
      <div className="experts-box__details">
        <h3>{title}</h3>
        <span>{job}</span>
      </div>
    </div>
  );
};

export default ExpertsBox;
